import React from "react";
import * as styles from "../policy/index.module.scss";
import { Typography } from "@components/";
import { msa } from "../policy/data/_msa";
import PolicyPage from "../policy/_PolicyPage";

export default function MSAPage() {
	return (
		<PolicyPage
			title="Master Subscription Agreement"
      content={msa}
      pageName={msa.pageName as 'msa'}
			preBody={
				<>
					<Typography variant="HEADER2" className={styles.subtitle}>
						Terms And Conditions
					</Typography>
					<Typography>
						This Agreement governs Customer’s acquisition and use of
						the Genemod Services as well as Genemod’s provision of
						the Genemod Services. The parties hereby agree as
						follows:
					</Typography>
				</>
			}
		/>
	);
}
