export const msa = {
	intro: [
		"THIS MASTER SUBSCRIPTION AGREEMENT (“AGREEMENT”) IS A LEGAL AGREEMENT BETWEEN CUSTOMER AND GENEMOD CORPORATION AND GOVERNS CUSTOMER’S USE OF THE GENEMOD SERVICES. CAPITALIZED TERMS HAVE THE DEFINITIONS SET FORTH HEREIN.",
		"BY ACCEPTING THIS AGREEMENT, BY (1) CLICKING A BOX INDICATING ACCEPTANCE, (2) EXECUTING AN ORDER FORM THAT REFERENCES THIS AGREEMENT, OR (3) USING GENEMOD SERVICES DURING TIRAL USE, CUSTOMER AGREES TO THE TERMS OF THIS AGREEMENT. IF THE INDIVIDUAL ACCEPTING THIS AGREEMENT IS ACCEPTING ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, SUCH INDIVIDUAL REPRESENTS THAT THEY HAVE THE AUTHORITY TO BIND SUCH ENTITY AND ITS AFFILIATES TO THESE TERMS AND CONDITIONS, IN WHICH CASE THE TERM “CUSTOMER” SHALL REFER TO SUCH ENTITY AND ITS AFFILIATES. IF THE INDIVIDUAL ACCEPTING THIS AGREEMENT DOES NOT HAVE SUCH AUTHORITY, OR DOES NOT AGREE WITH THESE TERMS AND CONDITIONS, SUCH INDIVIDUAL MUST NOT ACCEPT THIS AGREEMENT AND MAY NOT USE THE SERVICES. Genemod’s direct competitors are prohibited from accessing the Genemod Services, except with GENEMOD’s prior written consent. In addition, the Genemod Services may not be accessed for purposes of monitoring their availability, performance or functionality, or for any other benchmarking or competitive purposes.",
		"This Agreement was last updated on March 18 2022. It is effective between Customer and Genemod as of the date of Customer’s accepting this Agreement.",
	],
	body: [
		{
			title: "1. Definitions",
			index: 1,
			paragraph: [
				"<ul><li>“Affiliate(s)”".bold() +
					" means any person, firm, trust, partnership, corporation, company or other entity or combination thereof, which directly or indirectly, Controls the subject entity, is Controlled by the subject entity, or is under common Control with the subject entity. “Control,” for purposes of this definition, means direct or indirect ownership of more than fifty percent (50%) of the voting interests of the subject entity.</li></ul>",
				"<ul><li>“Authorized Reseller”".bold() +
					"means a Genemod reseller that is in good standing with Genemod under a fully-executed Genemod reseller agreement and is associated with an Order Form under this Agreement.</li></ul>",
				"<ul><li>“Authorized Users”".bold() +
					" means individuals who are authorized by Customer to use the Genemod Services and who have been supplied user identifications and passwords by Customer, by registering on the Site or by Genemod at Customer’s request.</li></ul>",
				"<ul><li>“Beta Services”".bold() +
					" means Genemod Services or functionality that may be made available to Customer to try at its option at no  additional  charge  which  is  clearly  designated  as  beta,  pilot,  limited  release,  developer  preview,  non-production, evaluation, or by a similar description.</li></ul>",
				"<ul><li>“Content”".bold() +
					"means information obtained by Genemod from publicly available sources or its third party content providers and made available to Customer through the Genemod Services or pursuant to an Order Form, as more fully described in the Documentation.</li></ul>",
				"<ul><li>“Customer”".bold() +
					" means in the case of an individual accepting this Agreement on his or her own behalf, such individual, or in the case of an individual accepting this Agreement on behalf of a company or other legal entity, the company or other legal entity for which such individual is accepting this Agreement, and Affiliates of that company or entity (for so long as they remain Affiliates) which have entered into Order Forms.</li></ul>",
				"<ul><li>“Customer Data”".bold() +
					" means all electronic data or information submitted by or on behalf of Customer to the Genemod Services, excluding Content and Non-Genemod Applications.</li></ul>",
				"<ul><li>“Documentation”".bold() +
					" means Genemod’s then-current guides and manuals describing the use and operation of the Genemod Services that Genemod makes generally available for the Genemod Services.</li></ul>",
				"<ul><li>“Free Trial”".bold() +
					" means a period of time during which Genemod provides the Genemod Services to Customer free of charge.</li></ul>",
				"<ul><li>“Genemod Service”".bold() +
					" means the generally available web-based, on-line, hosted software listed on an Order Form including, without  limitation,  all  corrections,  updates,  modifications,  releases,  versions,  and  enhancements  to  such  software  that Genemod releases to its customers generally.</li></ul>",
				"<ul><li>“Malicious Code”".bold() +
					" means viruses, worms, time bombs, Trojan horses, malware, and other harmful or malicious code, files, scripts, agents or programs.</li></ul>",
				"<ul><li>“Non-Genemod  Application”".bold() +
					" means  a  Web-based,  mobile,  offline  or  other  software  application  functionality  that interoperates with a Genemod Service, that is provided by Customer or a third party and/ or made available by Genemod. Non-Genemod Applications, other than those obtained or provided by Customer, will be identifiable as such.</li></ul>",
				"<ul><li>“Order Form”".bold() +
					" means a document for purchases of subscriptions to the Genemod Services hereunder, including purchase orders,  order  notifications  and  order  confirmation  documents  and  addenda  thereto,  that  are  agreed  to  by  Genemod,  or entered into between Genemod and Customer or Customer and an Authorized Reseller, as applicable, from time to time.</li></ul>",
				"<ul><li>“Site”".bold() +
					" means  Genemod’s  website  from  which  the  Genemod  Service  is  made  available  to  customers  accessible  at [https://app.genemod.net/login=.</li></ul>",
				"<ul><li>“Subscription Term”".bold() +
					" means the period of time that Customer may use and access the Genemod Services as set forth in the applicable Order Form.</li></ul>",
			],
		},
		{
			title: "2. Services",
						paragraph: [
				"2.1 Provision.".bold() +
					" Subject to the terms and conditions of this Agreement, Genemod shall provide to Customer during each Subscription Term a non-exclusive and nontransferable (except as set forth in Section 12.3 below) right to access and use of the Genemod Services and Content via the internet. Customer’s purchase of subscription(s) for the Genemod Services is neither contingent upon the delivery of any future functionality or features, nor dependent upon any oral or written public comments made by Genemod with respect to future functionality or features.",
				"2.2 Restrictions.".bold() +
					" The rights granted in Section 2.1 above are conditioned upon Customer’s compliance with the terms and conditions of this Agreement. Customer may use the Genemod Services and Content solely for Customer’s own internal business purposes, in compliance with applicable law. Customer shall not: (1) permit any third party to access the Genemod Services or Content except as permitted herein or in an Order Form, (2) license, sublicense, sell, resell, rent, lease, transfer, distribute, use the Genemod Services or Content for commercial time sharing, outsourcing or otherwise commercially exploit the Genemod Services or Content; (3) create derivative works based on the Genemod Services or Content; (4) modify, reverse engineer, translate, disassemble, or decompile the Genemod Services or Content, or cause or permit others to do so; (5) copy, frame or mirror any content forming part of the Genemod Services or Content, other than on Customer’s own intranets or otherwise for Customer’s own internal business purposes; (6) access the Genemod Services in order to (A) build a competitive product or service, or (B) copy any features, functions or graphics of the Genemod Services; and (7) remove any title, trademark, copyright and/or restricted rights notices or labels from the Genemod Services, Content or Documentation.",
				"2.3 Account Registration and Access.".bold() +
					" In order to use the Genemod Services, Authorized Users must create an account (“Account”) via the Site through Genemod's online registration procedures which Genemod may update from time to time.  This Agreement will govern the use of the Genemod Services and will control in the event of a conflict with the Genemod Terms of Service at the Site (" +
					"<u>“Terms of Service”</u>".link(
						"https://genemod.net/terms-of-service/"
					) +
					").",
				"2.4 Non-Genemod Applications and Services.".bold() +
					" Genemod or third parties may make available third-party products or services, including, for example, Non-Genemod Applications and implementation and other consulting services. Any acquisition by Customer of such products or services, and any exchange of data between Customer and any Non-Genemod provider, product or service is solely between Customer and the applicable Non-Genemod provider. Genemod does not warrant or support Non-Genemod Applications or other Non-Genemod Applications or services, whether or not they are designated by Genemod as “certified” or otherwise, unless expressly provided otherwise in an Order Form. Genemod is not responsible for any disclosure, modification or deletion of Customer Data resulting from access by such Non-Genemod Application or its provider.",
				"2.5 Integration with Non-Genemod Applications.".bold() +
					" The Genemod Services may contain features designed to interoperate with Non-Genemod Applications. Genemod cannot guarantee the continued availability of such Genemod Service features, and may cease providing them without entitling Customer to any refund, credit, or other compensation, if for example and without limitation, the provider of a Non-Genemod Application ceases to make the Non-Genemod Application available for interoperation with the corresponding Genemod Service features in a manner acceptable to Genemod.",
				"2.6 Support.".bold() +
					" During the Subscription Term, and at no additional charge to the Customer, Genemod will provide Customer with Genemod's standard level of support.",
				"2.7 Free Trial and Beta Services.".bold() +
					" During the Free Trial Genemod will make the applicable Service(s) available to Customer on a trial basis free of charge until the earlier of (a) the end of the Free Trial, or (b) termination by Genemod in its sole discretion. From time to time, Genemod may make Beta Services available to Customer at no charge. Customer may choose to try such Beta Services or not in its sole discretion. Use of the Beta Services is permitted only for Customer’s internal evaluation during the period designated by Genemod (or if not designated, 30 days) (" +
					"“Beta Term”".bold() +
					"). Either party may terminate Beta Services at any time for any reason. Beta Services may be inoperable, incomplete or include features that Genemod may never release, and their features and performance information are Genemod's Confidential Information.",
				"NOTWITHSTANDING SECTION 7 (REPRESENATIONS AND WARRANTIES) AND SECTION 9.1 (INDEMNIFICATION BY GENEMOD), DURING THE FREE TRIAL OR BETA TERM THE SERVICES ARE PROVIDED “AS-IS” WITHOUT ANY WARRANTY AND GENEMOD SHALL HAVE NO INDEMNIFICATION OBLIGATIONS NOR LIABILITY OF ANY TYPE WITH RESPECT TO THE SERVICES FOR THE FREE TRIAL OR BETA TERM UNLESS SUCH EXCLUSION OF LIABILITY IS NOT ENFORCEABLE UNDER APPLICABLE LAW IN WHICH CASE GENEMOD’S LIABILITY WITH RESPECT TO THE SERVICES PROVIDED DURING THE FREE TRIAL OR BETA TERM SHALL NOT EXCEED $1,000.00. WITHOUT LIMITING THE FOREGOING, GENEMOD AND ITS AFFILIATES AND ITS LICENSORS DO NOT REPRESENT OR WARRANT TO CUSTOMER THAT: (A) CUSTOMER’S USE OF THE GENEMOD SERVICES DURING THE FREE TRIAL OR BETA TERM WILL MEET CUSTOMER’S REQUIREMENTS, (B) CUSTOMER’S USE OF THE GENEMOD SERVICES DURING THE FREE TRIAL OR BETA TERM WILL BE UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR, AND (C) USAGE DATA PROVIDED DURING THE FREE TRIAL OR BETA TERM WILL BE ACCURATE. NOTWITHSTANDING ANYTHING TO THE CONTRARY IN SECTION 8 (LIMITATION OF LIABILITY), CUSTOMER SHALL BE FULLY LIABLE UNDER THIS AGREEMENT TO GENEMOD AND ITS AFFILIATES FOR ANY DAMAGES ARISING OUT OF CUSTOMER’S USE OF THE GENEMOD SERVICES DURING THE FREE TRIAL OR BETA TERM, ANY BREACH BY CUSTOMER OF THIS AGREEMENT AND ANY OF CUSTOMER’S INDEMNIFICATION OBLIGATIONS HEREUNDER.",
				"2.8 Professional Services.".bold() +
					" Genemod will perform implementation, configuration or other professional services specified in a Statement of Work ( " +
					"“SOW”".bold() +
					") signed by both parties (" +
					"“Professional Services”".bold() +
					").",
			],
		},
		{
			title: "3. User of Services",
			index: 3,
			paragraph: [
				"3.1 Genemod’s Responsibilities.".bold(),
				"\t(a) Genemod shall use commercially reasonable efforts to make the Genemod Services available via the internet  except for: (i) planned downtime (of which Genemod shall give advance electronic notice as provided in the Documentation), and (ii) any unavailability caused by circumstances beyond Genemod’s reasonable control, including, for example, an act of God, act of government, epidemic, pandemic, flood, fire, earthquake, civil unrest, act of terror, strike or other labor problem (other than one involving Genemod’s employees), general unavailability of Internet service or infrastructure, failures of Non-Genemod Application, or denial of service attacks.</li></ul>",
				"\t(b) Genemod will (i) implement and maintain appropriate security measures, including, without limitation, technical, physical, administrative and organizational controls, designed to maintain the confidentiality, security and integrity of Customer Data; (ii) implement and maintain systems and procedures for detecting, preventing and responding to attacks, intrusions, and system failures, and regularly test and monitor the effectiveness of such systems and procedures (including through vulnerability scans and penetration testing); (iii) designate employees to coordinate implementation and maintenance of its security; and (iv) identify internal and external risks to the security, confidentiality and integrity of Customer Data that could result in the unauthorized disclosure, misuse, alteration, destruction or other compromise of Customer Data, and assess the sufficiency of systems and procedures in place to control these risks. If Genemod becomes aware that Customer Data was accessed or disclosed in breach of this Agreement, Genemod will so notify Customer without undue delay, immediately act to mitigate the breach and preserve forensic evidence, and provide information to Customer regarding the nature and scope of the breach.</li></ul>",
				"3.2 Customer’s Responsibilities.".bold() +
					" Customer will (a) be responsible for Authorized Users’ compliance with this Agreement; and (b) be responsible for the accuracy, quality and legality of Customer Data, the means by which Customer acquired Customer Data, Customer’s use of Customer Data with the Genemod Services, and the interoperation of any Non-Genemod Applications with which Customer uses Genemod Services or Content. Customer shall not (i) use the Genemod Services to store or transmit infringing, libelous, or otherwise unlawful or tortious material, or to store or transmit material in violation of third-party privacy rights, (ii) use the Genemod Services or Non-Genemod Application to store or transmit Malicious Code, (iii) interfere with or disrupt the integrity or performance of the Genemod Services, or third party data contained therein, or (iv) attempt to gain unauthorized access to the Genemod Services or its related systems or networks. Any conduct by Customer that in Genemod’s discretion restricts or inhibits any of Genemod’s other customers from using or enjoying the Genemod Services is expressly prohibited. Customer shall use commercially reasonable efforts to prevent unauthorized access to, or use of, the Genemod Services, and notify Genemod promptly of any such unauthorized access or use. Customer is responsible for obtaining and maintaining all telephone, computer hardware and other equipment needed for access to and use of the Genemod Services and all charges related thereto.",
			],
		},
		{
			title: "4. Fees",
			index: 4,
			paragraph: [
				"4.1 Payment.".bold() +
					" Customer shall pay fees in accordance with the amounts and dates specified on the applicable Order Form or Statement of Work. The subscription fees set forth in each Order Form are fixed during the Subscription Term. The Subscription Term and fees set forth in each Order Form hereunder will be invoiced upon execution of such Order Form. All fees are based on services purchased and not actual usage, payment obligations are non-cancelable, payment terms are quoted from the date of invoice and fees paid are non-refundable. If Customer has not used Professional Services within one (1) year of payment for such Professional Services, Genemod's obligation to provide such Professional Services terminates and Customer shall not be entitled to a refund, unless set forth in the applicable SOW.",
				"4.2 Billing.".bold() +
					" Fees are due thirty (30) days from the invoice date, unless otherwise noted in an Order Form.  Customer agrees to provide Genemod or the applicable Authorized Reseller, with complete and accurate billing and contact information and to notify Genemod or the applicable Authorized Reseller of any changes to such information. The parties agree that if Customer purchases subscriptions through an Authorized Reseller and such Authorized Reseller’s order form or invoice includes different payment terms, the Authorized Reseller’s payment terms shall prevail.  If Customer does not pay fees when due, late charges may be added at the rate of 1.5% of the outstanding balance per month, or the maximum rate permitted by law, whichever is lower, from the date such payment was due until the date paid. Customer shall reimburse Genemod for all reasonable, actual costs (including reasonable attorneys’ fees) incurred by Genemod in the collection of overdue amounts. The parties agree that if Customer purchases subscriptions through an Authorized Reseller and such Authorized Reseller’s order form or invoice includes different overdue charges, the Authorized Reseller’s overdue charges terms shall prevail.",
				"4.3 Taxes.".bold() +
					" If Genemod has the legal obligation to pay or collect taxes for which Customer is responsible, including but not limited to, sales, use, transfer, privilege, excise, and all other taxes and duties that are levied or imposed by reason of Genemod’s performance under this Agreement, Genemod shall invoice the appropriate amount to Customer. Customer shall pay all applicable taxes, unless Customer provides Genemod with a valid tax exemption certificate authorized by the appropriate taxing authority.",
				"4.4 Suspension of Services.".bold() +
					"If any amount owed by Customer is thirty (30) days or more overdue, Genemod may, with seven (7) days’ prior notice to Customer, without limiting Genemod’s other rights and remedies, suspend Genemod Services until Customer pays such amounts owed. Genemod shall not exercise its rights under this Section 4.4 if the applicable charges are under reasonable and good-faith dispute and Customer is cooperating diligently to resolve the dispute. If Customer believes, in good faith, that an invoice contains incorrect fee information, Customer shall send a written notice to Genemod or its Authorized Reseller within fifteen (15) days of receipt of invoice (“Dispute Period”) providing a reasonably detailed explanation of the nature of the dispute, and specifying the dollar amounts withheld and the reasons for withholding such amounts. If Genemod or Authorized Reseller does not respond to the notice within thirty (30) days, Customer’s determination as to the correct fee information set forth in the written notice shall be final. If Customer does not dispute the applicable invoice during the Dispute Period, any such dispute shall be deemed waived. Customer remains obligated to pay Genemod or the Authorized Reseller for all portions of the applicable invoice that are not under reasonable and good faith dispute. The parties shall work together expeditiously and in good faith to resolve all fee disputes. The parties agree that if Customer purchases subscriptions through an Authorized Reseller and such Authorized Reseller’s order form or invoice includes different payment dispute procedures, the Authorized Reseller’s payment dispute procedures shall prevail.",
			],
		},
		{
			title: "5. Intellectual Property Rights",
			index: 5,
			paragraph: [
				"5.1 General.".bold() +
					" All right, title, and interest in and to the Genemod Services, Genemod’s Confidential Information, and Documentation, including, without limitation, all modifications, enhancements, derivative works and intellectual property rights thereto shall belong solely to Genemod and/or its applicable suppliers.",
				"5.2 Reserved Rights.".bold() +
					" Genemod does not grant any rights in and to the Genemod Services except as expressly written in this Agreement. Nothing in this Agreement shall limit in any way Genemod’s right to develop, use, license, create derivative works of, or otherwise exploit the Genemod Services or to permit third parties to do so.",
				"5.3 Ownership of Customer Data.".bold() +
					" Customer exclusively owns all right, title and interest in and to Customer Data.",
				"5.4 License to Genemod.".bold() +
					" Customer hereby grants, and shall grant, to Genemod a worldwide, royalty-free, non-exclusive, perpetual right to access, use, reproduce and prepare derivative works based on Customer Data for the purpose of improving or enhancing the Genemod Service. Customer is responsible for ensuring that it has obtained all necessary third-party consents and made all required disclosures to enable the foregoing grant.",
				"5.5 Suggestions.".bold() +
					" Customer or its representatives may provide Genemod with suggestions, enhancement requests, recommendations or other feedback relating to the Genemod Services (" +
					"“Feedback”".bold() +
					"). Nothing in this Agreement or in the parties’ dealings arising out of or related to this Agreement will restrict Genemod’s right to use, profit from, disclose, publish, keep secret, or otherwise exploit Feedback, or require Genemod to compensate or credit Customer or the individual providing such Feedback.",
			],
		},
		{
			title: "6. Confidentiality",
			index: 6,
			paragraph: [
				"6.1 Definition of Confidential Information.".bold() +
					" As used herein, " +
					"“Confidential Information”".bold() +
					" means all confidential and proprietary information of a party (the " +
					"“Disclosing Party”".bold() +
					") disclosed to the other party (the " +
					"“Receiving Party”".bold() +
					"), whether orally or in writing, that is designated as confidential or that reasonably should be understood to be confidential given the nature of the information and the circumstances of disclosure, including the terms and conditions of this Agreement. Genemod’s Confidential Information includes this Agreement, Aggregate Data, the Genemod Services, Content, business and marketing plans, technology, financial and technical information, product designs, and business processes. Customer’s Confidential Information includes Customer Data. Confidential Information (except for Customer Data) does not include any information that: (1) is or becomes generally known to the public without breach of any obligation owed to the Disclosing Party; (2) was known to the Receiving Party prior to its disclosure by the Disclosing Party without breach of any obligation owed to the Disclosing Party; (3) was independently developed by the Receiving Party without breach of any obligation owed to the Disclosing Party; or (4) is received from a third party without breach of any obligation owed to the Disclosing Party.",
				"6.2 Confidentiality.".bold() +
					" The Receiving Party shall not disclose or use any Confidential Information of the Disclosing Party for any purpose outside the scope of this Agreement, except with the Disclosing Party's prior written permission. Notwithstanding the foregoing, Genemod may disclose the terms of this Agreement and any applicable Order Form to a subcontractor or Non- Genemod Application provider to the extent necessary to perform Genemod’s obligations under this Agreement, under terms of confidentiality materially as protective as set forth herein.",
				"6.3 Protection.".bold() +
					" The Receiving Party shall protect the confidentiality of the Disclosing Party’s Confidential Information it receives in the same manner that it protects the confidentiality of its own proprietary and confidential information of like kind (but in no event using less than reasonable care). Genemod shall notify Customer promptly in the event a security breach related to Customer Data.",
				"6.4 Compelled Disclosure.".bold() +
					" If the Receiving Party is compelled by law to disclose Confidential Information of the Disclosing Party, it shall provide the Disclosing Party with prior notice of such compelled disclosure (to the extent legally permitted) and reasonable assistance, at Disclosing Party's cost, if the Disclosing Party wishes to contest the disclosure.",
				"6.5 Remedies.".bold() +
					" If the Receiving Party discloses or uses (or threatens to disclose or use) any Confidential Information of the Disclosing Party in breach of confidentiality protections hereunder, the Disclosing Party may, in addition to any other remedies available to it, seek injunctive relief to enjoin such acts, it being specifically acknowledged by the parties that any other available remedies are inadequate.",
			],
		},
		{
			title: "7. Representations and Warranties",
			index: 7,
			paragraph: [
				"7.1 Mutual Warranties.".bold() +
					" Each party represents and warrants that (a) it has the legal power to enter into this Agreement, and (b) it will not transmit to the other party any Malicious Code. ",
				"7.2 Genemod’s Warranty.".bold() +
					" Genemod warrants during the Subscription Term (a) that the Genemod Services will be free of material defects and will function in substantial conformance to the Documentation and (b) Professional Services will be performed in a manner consistent with generally accepted industry standards. To the extent permitted by applicable law, THE FOREGOING LIMITED WARRANTIES ARE IN LIEU OF ALL OTHER WARRANTIES OR CONDITIONS, EXPRESS OR IMPLIED, AND GENEMOD DISCLAIM ANY AND ALL OTHER WARRANTIES OR CONDITIONS, WHETHER EXPRESS, IMPLIED, ORAL OR WRITTEN, INCLUDING, WITHOUT LIMITATION, ANY AND ALL IMPLIED WARRANTIES OF MERCHANTABILITY, QUALITY, REASONABLE CARE, AND/OR FITNESS FOR A PARTICULAR PURPOSE (WHETHER OR NOT GENEMOD KNOWS, HAS REASON TO KNOW, HAS BEEN ADVISED, OR IS OTHERWISE IN FACT AWARE OF ANY SUCH PURPOSE). TO THE EXTENT PERMITTED BY APPLICABLE LAW, GENEMOD FURTHER DISCLAIMS ANY AND ALL WARRANTIES, CONDITIONS, AND/OR REPRESENTATIONS OF TITLE AND NON-INFRINGEMENT. CONTENT AND BETA SERVICES ARE PROVIDED “AS-IS”, AND AS AVAILABLE EXCLUSIVE OF ANY WARRANTY WHATSOEVER. No action for breach of the limited warranty set forth in this Section 7.2 may be commenced more than one (1) year following the expiration of the applicable Subscription Term.",
				"7.3 ".bold() +
					"Customer's sole and exclusive remedy and Genemod's entire liability for any breach of the warranty set forth in Section 7.2(b) shall be the re-performance of the Professional Services, or if Genemod is unable to perform the Professional Services as warranted, Customer shall be entitled to recover the fees paid to Genemod for the nonconforming Professional Services.",
			],
		},
		{
			title: "8. Damages and Limitation of Liability",
			index: 8,
			paragraph: [
				"8.1 Consequential Damages.".bold() +
					" NEITHER PARTY IS LIABLE FOR ANY LOST PROFITS OR FOR INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES, HOWEVER CAUSED AND WHETHER IN CONTRACT, TORT OR UNDER ANY OTHER THEORY OF LIABILITY, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THIS AGREEMENT, OR THE GENEMOD SERVICES, EVEN IF EITHER PARTY IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ",
				"8.2 Limitation of Liability.".bold() +
					" EXCEPT FOR BREACH OF SECTIONS 2.2 (RESTRICTIONS) OR 3.2 (CUSTOMER RESPONSIBILITIES), INDEMNIFICATION OBLIGATIONS UNDER SECTION 9.2 (INDEMNIFICATION BY CUSTOMER) OR INFRINGEMENT OR MISAPPROPRIATION OF A PARTY'S INTELLECTUAL PROPERTY RIGHTS, EACH PARTY'S AGGREGATE LIABILITY FOR CLAIMS RELATING TO THIS AGREEMENT OR THE GENEMOD SERVICES, WHETHER FOR BREACH OF CONTRACT OR IN TORT OR UNDER ANY OTHER THEORY OF LIABILITY, IS LIMITED TO THE GREATER OF (1) THE AMOUNT ACTUALLY PAID BY CUSTOMER TO GENEMOD HEREUNDER DURING THE 6-MONTH PERIOD IMMEDIATELY BEFORE THE CLAIM WHICH GAVE RISE TO THE LIABILITY OR (2) THE ANNUALIZED SUBSCRIPTION VALUE AT THE TIME OF SUCH CLAIM. THE LIMITATIONS SET FORTH IN THIS SECTION 8 SHALL APPLY NOTWITHSTANDING THE FAILURE OF THE ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.",
			],
		},
		{
			title: "9. Mutual Indemnification",
			index: 9,
			paragraph: [
				"9.1 Indemnification by Genemod.".bold(),
				"\t(a) Genemod will defend Customer against any claim, demand, suit or proceeding made or brought against Customer by a third party alleging that any Genemod Services infringes or misappropriates such third party’s intellectual property rights (a “Claim Against Customer”), and will indemnify Customer from any damages, attorney fees and costs finally awarded against Customer as a result of, or for amounts paid by Customer under a settlement approved by Genemod in writing of, a Claim Against Customer, provided, that Customer (1) promptly gives written notice of the Claim Against Customer to Genemod; (2) gives Genemod sole control of the defense and settlement of the Claim Against Customer (provided that Genemod may not settle or defend any Claim Against Customer unless Genemod unconditionally release Customer of all liability); and (3) provides to Genemod, at Genemod’s cost, all reasonable assistance.",
				"\t(b) In the event Genemod is required to indemnify Customer in accordance with this Section 9.1, or if Genemod reasonably believes the Genemod Services may infringe a third party's intellectual property rights, then Genemod may (1) modify the Genemod Services so that it no longer infringes without materially adversely affecting its operation, or materially altering its capacity or performance, (2) obtain a license for Customer’s continued use of the Genemod Services, and/or (3) remove the infringing component from the Genemod Services without materially adversely affecting its operation, or materially altering its capacity or performance. If, despite Genemod’s commercially reasonable efforts to do so, the foregoing options are not reasonably practicable, then Genemod may terminate this Agreement, in which case Genemod shall issue to Customer a refund of all prepaid fees covering the remainder of the then-current Subscription Term(s) from the point in time when Customer were unable to make use of the Genemod Services due to the third-party infringement claim.",
				"\t(c) Genemod is not liable for any Claim Against Customer to the extent such liability is the result of (1) modifications to the Genemod Services by anyone other than Genemod or Genemod’s agents (and where Genemod or Genemod’s agents made the modifications using requirements, documents, written specifications or other written materials submitted by Customer or Customer’s agents or representatives, Genemod is also not liable); (2) the use or combination of the Genemod Services with any other item not provided by Genemod where in the absence of such use or combination, the Genemod Services alone would not have given rise to the Claim Against Customer; (3) Customer’s continued use of an infringing version of the Genemod Services when the then-current version of the Genemod Services has been modified to be non-infringing; or (4) Claim Against Customer arises from a Non-Genemod Application, Content or Customer’s breach of this Agreement.",
				"9.2 Indemnification by Customer.".bold() +
					" Customer will defend Genemod and its Affiliates against any claim, demand, suit or proceeding made or brought against Genemod by a third party alleging that the Customer Data, or Customer’s use of the Genemod Services in violation of this Agreement, infringes the intellectual property rights of, or has otherwise harmed, a third party; (" +
					"“Claim Against Genemod”".bold() +
					"),  and will indemnify Genemod from any damages, attorney fees and costs finally awarded against Genemod as a result of, or for any amounts paid by Genemod under a settlement approved by Customer in writing of, a Claim Against Genemod, provided that Genemod (1) promptly gives written notice of the Claim Against Genemod to Customer; (2) gives Customer sole control of the defense and settlement of the Claim Against Genemod (provided that Customer may not settle or defend any Claim Against Genemod unless it unconditionally releases Genemod of all liability); and (3) provides to Customer, at Customer’s cost, all reasonable assistance.",
				"9.3 Exclusive Remedy.".bold() +
					"This “Mutual Indemnification” section states the indemnifying party’s sole liability to, and the indemnified party’s exclusive remedy against, the other party for any type of Claim described in this section.",
			],
		},
		{
			title: "10. Access and Monitoring",
			index: 10,
			paragraph: [
				"Genemod may access Customer’s account and Customer Data as necessary to identify or resolve technical problems or respond to complaints about the Genemod Services. Without limiting the foregoing and with two (2) days prior written notice, Genemod may remove any material submitted to the Genemod Services that Genemod finds to be in violation of the provisions hereof.",
			],
		},
		{
			title: "11. Term and Termination",
			index: 11,
			paragraph: [
				"11.1 Term of Agreement.".bold() +
					" This Agreement terminates when all Subscription Terms expire or all Order Forms are otherwise terminated.",
				"11.2 Renewal.".bold() +
					" Each Order Form shall automatically renew for additional Subscription Terms of the same duration as the immediately preceding Subscription Term (but no less than 30 days), unless either party gives the other notice of non-renewal at least 30 days prior to the end of the relevant Subscription Term. To be valid, Customer’s notice of non-renewal must be delivered to Genemod at Genemod@Genemod.net. For each automatic renewal, per-unit subscription pricing will increase by no more than 10% as compared to the prior term unless Genemod provides Customer with notice of different pricing at least 30 days prior to the applicable renewal. A new Order Form is not required unless additional or different services are ordered.",
				"11.3 Termination.".bold() +
					" A party may terminate this Agreement or any Order Form for cause (i) upon thirty (30) days prior written notice to the other party of a material breach by the other party if such breach remains uncured at the expiration of such period; (ii) immediately upon written notice if the other party becomes the subject of a bankruptcy, insolvency, receivership, liquidation, assignment for the benefit of creditors or similar proceeding; or (iii) as otherwise provided herein. ",
				"11.4 Effects of Termination.".bold() +
					" Upon any expiration or termination of this Agreement, and upon expiration of the Subscription Term if Customer does not renew in accordance with Section 11.2, the rights and licenses granted hereunder will automatically terminate, and Customer may not continue to use the Genemod Services. If the Agreement is terminated based upon Genemod’s uncured material breach, Genemod shall refund to Customer any prepaid fees covering the remainder of Customer’s Subscription Term after the date of such termination. If the Agreement is terminated based on Customer’s uncured material breach, Customer shall pay any unpaid fees covering the remainder of the Subscription Term(s) of all Order Forms after the effective date of such termination. Termination of this Agreement shall not limit the parties from pursuing any other remedies available to it, including injunctive relief.",
				"11.5 Data Export.".bold() +
					" During the Subscription Term or within 30 days thereafter upon Customer’s written request, Customer may export its Customer Data from the Genemod Service using the export features described in the Documentation.",
			],
		},
		{
			title: "12. Miscellaneous",
			index: 12,
			paragraph: [
				"12.1 General.".bold() +
					" This Agreement is intended for the sole and exclusive benefit of the parties and is not intended to benefit any third party. Only the parties to this Agreement may enforce it. The parties are independent contractors, and no branch or agency, partnership, association, joint venture, employee-employer, or franchiser-franchisee relationship is intended or created by this Agreement. Headings in this Agreement are for the convenience of the parties only. Accordingly, they do not constitute a part of this Agreement when interpreting or enforcing this Agreement.",
				"12.2 Severability.".bold() +
					" If any portion hereof is found to be void or unenforceable, the remaining provisions of this Agreement shall remain in full force and effect.",
				"12.3 Assignment.".bold() +
					" Neither party may assign any of its rights or obligations hereunder, whether by operation of law or otherwise, without the prior written consent of the other party (not to be unreasonably withheld). Notwithstanding the foregoing, either party may assign this Agreement in its entirety, without consent of the other party, to its Affiliates or in connection with a merger, acquisition, corporate reorganization, or sale of all or substantially all its assets not involving a direct competitor of the other party. Subject to the foregoing, this Agreement shall bind and inure to the benefit of the parties, their respective successors and permitted assigns.",
				"12.4 Entire Agreement; Breach and Waiver; Amendment.".bold() +
					" This Agreement, including all Order Forms, constitutes the complete and exclusive understanding and agreement between the parties regarding their subject matter and supersede all prior or contemporaneous agreements or understandings, written or oral, relating to their subject matter. Genemod may change the terms of this Agreement at any time by posting a new version of this Agreement online and notifying Customer of such changes. Customer shall have 30 days to object to such changes in a Legal Notice (defined below) delivered to Genemod in accordance with Section 12.10, and if Genemod does not receive such objection within such 30 days all changes shall be deemed accepted. If Customer does timely object to the changes, then the amended Agreement shall not take effect until the next renewal of a Subscription Term. Any waiver, modification or amendment of any provision of this Agreement will be effective only if in writing and signed by duly authorized representatives of the party against whom the waiver, modification or amendment is to be asserted. Notwithstanding anything to the contrary, this Agreement shall supersede the terms of any Customer purchase order or other business form. To the extent of any conflict or inconsistency between the provisions in the body of this Agreement and an Order Form, the terms of the Order Form shall prevail. To the extent of any conflict or inconsistency between the provisions in the body of this Agreement and the Terms of Service, the provisions of this Agreement shall prevail. No waiver of any breach of this Agreement shall constitute a waiver of a subsequent breach, whether or not of the same nature. All waivers shall be strictly construed. No delay in enforcing any right or remedy as a result of a breach of this Agreement shall constitute a waiver thereof. Accordingly, no course of conduct shall constitute an amendment or modification of this Agreement.",
				"12.5 Force Majeure.".bold() +
					" Subject to the further provisions of this section, any delays or failures by either party in the performance of its obligations hereunder shall be excused if and to the extent such delays or failures are caused by occurrences beyond such party’s reasonable control, including, without limitation, acts of God, strikes or other labor disturbances, war, whether declared or not, sabotage, and/or any other cause or causes, whether similar or dissimilar to those herein specified, which cannot reasonably be controlled by such party. Performance will be excused only during the actual period an occurrence continues. Accordingly, neither party may terminate this Agreement for cause on account of a failure of the other party timely to perform its obligations hereunder during the period of such excused performance pursuant to the foregoing.",
				"12.6 Federal Government End Use Provisions.".bold() +
					" Genemod provides the Genemod Services, including related software and technology, for ultimate federal government end use solely in accordance with the following: Government technical data and software rights related to the Genemod Services include only those rights customarily provided to the public as defined in this Agreement. This customary commercial license is provided in accordance with FAR 12.211 (Technical Data) and FAR 12.212 (Software) and, for Department of Defense transactions, DFAR 252.227-7015 (Technical Data – Commercial Items) and DFAR 227.7202-3 (Rights in Commercial Computer Software or Computer Software Documentation). In the event that a government agency may need rights not conveyed under these terms, it must negotiate with Genemod to determine if there are acceptable terms for transferring such rights, and a mutually acceptable written addendum specifically conveying such rights must be included in any applicable contract or agreement.",
				"12.7 Governing Law.".bold() +
					" The laws of the State of Washington govern this Agreement as if performed wholly within the state and without giving effect to the principles of conflict of law. The United Nations Convention on Contracts for the International Sale of Goods shall not apply. Any legal actions or proceeding arising under this Agreement must be brought exclusively in the state or federal courts for the Western District of Washington and the parties hereby consent to personal jurisdiction and venue therein.",
				"12.8 Customer’s Reference and Case Study.".bold() +
					" Customer agrees that upon Genemod's request, Customer will make a representative reasonably available to participate in reference inquiries from Genemod’s potential customers. Either party may include the other's name and logo in customer or vendor lists including those customer or vendor lists used in press releases.",
				"12.9 Survival.".bold() +
					" The parties’ rights and obligations under Sections 4 (Fees), 5 (Intellectual Property Rights), 6 (Confidentiality), 8 (Damages and Limitation of Liability), 9 (Mutual Indemnification), 11 (Term and Termination) and 12 (Miscellaneous) shall survive the termination of this Agreement for any reason.",
				"12.10 Notices.".bold() +
					" Except as otherwise specified in this Agreement, all notices related to this Agreement will be in writing and will be effective upon (a) personal delivery, (b) the second business day after mailing, or (c), except for notices of termination or an indemnifiable claim (“Legal Notices”), which shall clearly be identifiable as Legal Notices, the day of sending by email. Billing-related notices to Customer will be addressed to the relevant billing contact designated by Customer. All other notices to Customer will be addressed to the relevant Customer Contact designated by Customer in the applicable Order Form.",
				"12.11 Export Restrictions.".bold() +
					" Each party agrees to comply with all applicable regulations of the United States Department of Commerce and with the United States Export Administration Act, as amended from time to time, and with all applicable laws and regulations of other jurisdictions with respect to the importation and use of the Genemod Services and Genemod Confidential Information and any media, to assure that the Genemod Services, Genemod Confidential Information and media are not exported, imported or used in violation of law or applicable regulation.",
				"12.12 Restricted Use.".bold() +
					" Customer is prohibited from using the Genemod Services when headquartered in any of the following countries: Iraq, China, Russia, or any state listed on the United States State Department State Sponsors of Terrorism List found here " +
					"<u>https://www.state.gov/state-sponsors-of-terrorism/</u>".link(
						"https://www.state.gov/state-sponsors-of-terrorism/"
					) +
					". Authorized Users who are nationals or domiciled in these countries are exempt from this restriction.",
			],
		},
  ],
  pageName: 'msa'
};
